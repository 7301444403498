<template>
  <div class="gas_wrapper">
    <div class="base_box main_box">
      <div class="base_title">選擇公鏈</div>
      <div
        class="base_select mt20"
        :class="{ select_active: show1 }"
        
      >
        <div class="content">
          <img
            :src="chainObj[selectChain].logo"
            class="select_img"
            srcset=""
          />{{chainObj[selectChain].chainName}}
        </div>
        <div class="right_down" @click.stop="show1 = !show1"></div>
        <div class="downlist" v-click-outside="handleScroll">
          <div class="list_item" v-for="(item,index) in chains"
            @click="switchChain(item.chain)"
            :key="index">
            <img
              :src="item.logo"
              class="select_img"
              srcset=""
            />{{item.chainName}}
          </div>
        </div>
      </div>
      <div class="gas_item" style="border-color:#F2994A">
          <div class="title"><img src="../assets/img/gas1.png" alt="" srcset="">
          <div class="gas_num" style="color: #F2994A;">{{prices1}}</div></div>
          <div class="both_side">
          <div class="gas_time">15 seconds</div>
          <div class="gas_price">¥ {{cny1}} | <span class="mobile_br">$ {{usd1}}</span></div>
          </div>
      </div>
      <div class="gas_item" style="border-color:#2980FF">
          <div class="title"><img src="../assets/img/gas2.png" alt="" srcset="">
          <div class="gas_num" style="color: #2980FF;">{{prices2}}</div></div>
          <div class="both_side">
          <div class="gas_time">60 seconds</div>
          <div class="gas_price">¥ {{cny2}} | <span class="mobile_br">$ {{usd2}}</span></div>
          </div>
      </div>
      <div class="gas_item" style="border-color:#EB5757">
          <div class="title"><img src="../assets/img/gas3.png" alt="" srcset="">
          <div class="gas_num" style="color: #EB5757;">{{prices3}}</div></div>
          <div class="both_side">
          <div class="gas_time">180 seconds</div>
          <div class="gas_price">¥ {{cny3}} | <span class="mobile_br">$ {{usd3}}</span></div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
var axios = require("axios");
import { chainObj } from "../config";
import { showFromWei2, clickOutside, formatDecimal, multiply } from "../utils";
export default {
  directives: {clickOutside},
  data() {
    return {
      chainObj,
      show1: false,
      selectChain: 'BSC',
      prices1: 0,
      prices2: 0,
      prices3: 0,
      cny1: 0,
      cny2: 0,
      cny3: 0,
      usd1: 0,
      usd2: 0,
      usd3: 0,
    };
  },
  computed: {
    chains() {
      return Object.values(this.chainObj)
    },
  },
  created() {
    this.loadData()
  },
  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll, true);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.handleScroll, true);
  // },
  methods: {
    handleScroll() {
      this.show1 = false;
    },
    switchChain(chain) {
      console.log(chain)
      this.selectChain = chain;
      this.show1 = false;
      this.loadData();
    },
    loadData() {
      let that = this,
        selectChain = this.selectChain,
        q1,
        q2,
        q3;
      if (selectChain == "Heco") {
        q1 = "huobi-token";
        q2 = "bsc";
        q3 = "ht";
      } else if (selectChain == "BSC") {
        q1 = "binancecoin";
        q2 = "bsc";
        q3 = "bsc";
      } else if (selectChain == "Ethereum") {
        q1 = "ethereum";
        q2 = "eth";
        q3 = "eth";
      } else if (selectChain == "ARB") {
        q1 = "ethereum";
        q2 = "arb";
        q3 = "arb";
      } else if (selectChain == "OP") {
        q1 = "ethereum";
        q2 = "op";
        q3 = "op";
      } else if (selectChain == "Polygon") {
        q1 = "matic-network";
        q2 = "matic";
        q3 = "poly";
      } else if (selectChain == "Fantom") {
        q1 = "fantom";
        q2 = "ftm";
        q3 = "ftm";
      } else if (selectChain == "OKC") {
        q1 = "okexchain";
        q2 = "okt";
      } else if (selectChain == "CORE") {
        q1 = "coredaoorg";
        q2 = "bsc";
      }
      
      if (selectChain != "OKC" && selectChain != "CORE") {
        axios.get(`https://api.owlracle.info/v3/${q2}/gas?apikey=f6f64a2ae1914d7aa68dd122d7f68a22&accept=35,60,90&percentile=0.85`).then(res => {
        console.log(res)
        if(res.status == 200) {
          let speeds = res.data.speeds
          if(speeds && speeds.length) {
            this.prices1 = formatDecimal(speeds[2].gasPrice, 2)
            this.prices2 = formatDecimal(speeds[1].gasPrice, 2)
            this.prices3 = formatDecimal(speeds[0].gasPrice, 2)
            this.usd1 = formatDecimal(speeds[2].estimatedFee)
            this.usd2 = formatDecimal(speeds[1].estimatedFee)
            this.usd3 = formatDecimal(speeds[0].estimatedFee)
            this.cny1 = formatDecimal(multiply(speeds[2].estimatedFee * 7),2)
            this.cny2 = formatDecimal(multiply(speeds[1].estimatedFee * 7),2)
            this.cny3 = formatDecimal(multiply(speeds[0].estimatedFee * 7),2)
          }
          
        }
      })
      } else {
        axios
        .get(
          `https://api.coingecko.com/api/v3/simple/price?ids=${q1}&vs_currencies=cny,usd`
        )
        .then((res2) => {
          console.log(res2);
          let data2 = res2.data[q1];
          axios
            .get(
              `https://service.cointools.workers.dev/api/v1/gas_price?chain=${q2}`
            )
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                let data = res.data.data,
                  fast = data.fast.price,
                  normal = data.normal.price,
                  slow = data.slow.price;
                console.log(11111);
                that.prices1 = showFromWei2(fast / 1000000000, 2);
                that.prices2 = showFromWei2(normal / 1000000000, 2);
                that.prices3 = showFromWei2(slow / 1000000000, 2);
                that.cny1 = showFromWei2(
                  (fast * 21000 * data2.cny) / Math.pow(10, 18),
                  4
                );
                that.cny2 = showFromWei2(
                  (normal * 21000 * data2.cny) / Math.pow(10, 18),
                  4
                );
                that.cny3 = showFromWei2(
                  (slow * 21000 * data2.cny) / Math.pow(10, 18),
                  4
                );
                that.usd1 = showFromWei2(
                  (fast * 21000 * data2.usd) / Math.pow(10, 18),
                  4
                );
                that.usd2 = showFromWei2(
                  (normal * 21000 * data2.usd) / Math.pow(10, 18),
                  4
                );
                that.usd3 = showFromWei2(
                  (slow * 21000 * data2.usd) / Math.pow(10, 18),
                  4
                );
              }
            });
        });
      }
      
    },
  },
};
</script>

<style lang="less">
@import "../assets/pc/gas.less";
@import "../assets/mobile/gas.less";
</style>
